import React from "react";
import styled from "styled-components";

const Space = styled.div`
	height: 100px;

	@media (max-width: 768px) {
		height: 60px;
	}
`;

export const Spacer = () => <Space />;
