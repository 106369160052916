import React, { useEffect, useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Helmet from "react-helmet";

// Effects
import { Fade } from "react-awesome-reveal";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { BlogFilters } from "../components/navigation/blog-filters";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { Spacer } from "../components/utils/spacer";

// Context
import { FooterColor } from "../components/context/footer-color";
import { tertiary } from "../components/utils/colors";

const Page = styled.div`
	margin: 150px 0 0 0;
`;

const ArticleContainer = styled.div`
	& .article-text {
		margin: 15px 0 30px 0;

		& p {
			margin: 0;
		}
	}
`;

const Journal = ({ data }) => {
	const [activeFilter, setActiveFilter] = useState("All");
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const articles = data.allPrismicArticle.edges
		.filter((content) => {
			if (activeFilter === "All") {
				return content;
			} else {
				return content.node.tags.includes(activeFilter);
			}
		})
		.map((content, index) => (
			<Col col={12} md={6} lg={4} key={`journal_article_${index}`}>
				<Link to={content.node.url}>
					<Fade cascade triggerOnce>
						<ArticleContainer>
							<AspectRatioImageContainer image={null} padding={`66.667`}>
								{content.node.data.thumbnail.fluid !== null && (
									<img
										srcSet={content.node.data.thumbnail.fluid.srcSetWebp}
										src={content.node.data.thumbnail.fluid.srcWebp}
										alt={content.node.data.thumbnail.alt}
										loading={`lazy`}
									/>
								)}
							</AspectRatioImageContainer>
							<div className="article-text large">
								<p>{content.node.data.title.text}</p>
								<p>{content.node.data.author.text}</p>
								{/* <p>
									{content.node.data.date_override !== null &&
										content.node.data.date_override}
									{content.node.data.date_override === null &&
										content.node.first_publication_date}
								</p> */}
							</div>
						</ArticleContainer>
					</Fade>
				</Link>
			</Col>
		));

	return (
		<>
			<Helmet
				title={`${data.prismicBlog.data.seo_title}`}
				meta={[
					{
						name: "og:title",
						content: `${data.prismicBlog.data.seo_title}`,
					},
					{
						name: "description",
						content: `${data.prismicBlog.data.seo_description}`,
					},
					{
						name: "og:description",
						content: `${data.prismicBlog.data.seo_description}`,
					},
					{
						name: "twitter:title",
						content: `${data.prismicBlog.data.seo_title}`,
					},
					{
						name: "twitter:description",
						content: `${data.prismicBlog.data.seo_description}`,
					},
				]}
			/>

			<Page>
				<FlexContainer
					desktopPadding={`0 60px 0 135px`}
					tabletPadding={`0 35px 0 135px`}
					mobilePadding={`0 15px`}
					cv={false}
				>
					<Row>
						<BlogFilters
							activeFilter={activeFilter}
							setActiveFilter={setActiveFilter}
						/>
						<>{articles}</>
					</Row>
				</FlexContainer>
			</Page>
			<Spacer />
		</>
	);
};

export const query = graphql`
	{
		prismicBlog {
			data {
				title {
					html
				}
				seo_title
				seo_description
			}
		}
		allPrismicArticle(
			sort: {
				order: DESC
				fields: [data___date_override, first_publication_date]
			}
		) {
			edges {
				node {
					url
					first_publication_date(formatString: "DD/MM/YYYY")
					tags
					data {
						date_override(formatString: "DD/MM/YYYY")
						title {
							text
						}
						author {
							text
						}
						thumbnail {
							alt
							dimensions {
								height
								width
							}
							fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
								srcSetWebp
								srcWebp
								aspectRatio
							}
						}
					}
				}
			}
		}
	}
`;

export default withPreview(Journal);
