import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

// Effects
import { Fade } from "react-awesome-reveal";

// Styles
import { secondaryLight, primaryLight } from "../utils/colors";

const FilterContainer = styled.div`
	position: fixed;

	top: 145px;
	left: 0;

	width: 135px;

	z-index: 1;

	& ol {
		margin: 0;
		padding: 0 15px;

		& li {
			& a {
				&:hover {
					color: ${secondaryLight};
				}
			}

			& a.current {
				color: ${secondaryLight};
				&:hover {
					color: inherit;
				}
			}
		}
	}

	& ol.mobile-filter-button {
		display: none;
	}

	@media (max-width: 768px) {
		top: 52px;
		left: 0;
		right: 0;

		width: 100%;

		background-color: ${primaryLight};

		& ol.mobile-filter-button {
			display: block;
		}

		& ol.filter-list {
			display: ${(props) => props.isOpen};
		}

		& ol {
			& li {
				line-height: 50px;
			}
		}
	}
`;

const FilterButton = styled.button`
	transition: 150ms color ease;

	color: ${(props) => (props.isActiveFilter ? secondaryLight : `inherit`)};

	&:hover {
		color: ${secondaryLight};
	}
`;

export const BlogFilters = ({ activeFilter, setActiveFilter }) => {
	const [isOpen, setIsOpen] = useState(false);

	const data = useStaticQuery(graphql`
		{
			allPrismicArticle {
				edges {
					node {
						tags
					}
				}
			}
		}
	`);

	const filters = Array.from(
		new Set(
			data.allPrismicArticle.edges
				.filter(
					(filter) =>
						filter.node !== undefined &&
						filter.node !== null &&
						filter.node.tags !== undefined
				)
				.map((filter) => {
					return filter.node.tags.map((tag) => tag);
				})
				.flat()
		)
	).map((filter) => (
		<li key={`filter_${filter}`}>
			<FilterButton
				onClick={() => setActiveFilter(filter)}
				isActiveFilter={activeFilter === filter}
			>
				{filter}
			</FilterButton>
		</li>
	));

	useEffect(() => {
		let previousFilter;

		if (activeFilter !== previousFilter) {
			setIsOpen(false);
		}

		previousFilter = activeFilter;
	}, [activeFilter]);

	return (
		<FilterContainer isOpen={isOpen === true ? `block` : `none`}>
			<Fade cascade triggerOnce>
				<ol className="mobile-filter-button">
					<li>
						<button onClick={() => setIsOpen(!isOpen)}>
							{isOpen === true ? `Close` : `Filter`}
						</button>
					</li>
				</ol>

				<ol className="filter-list">
					<li>
						<FilterButton
							onClick={() => setActiveFilter("All")}
							isActiveFilter={activeFilter === "All"}
						>
							All
						</FilterButton>
					</li>

					{filters}
				</ol>
			</Fade>
		</FilterContainer>
	);
};
